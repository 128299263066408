(function() {

  var app = angular.module('priorWeb');

  app.constant('PaymentProviderEnum', {
    BIZUM: 'bizum',
    CASH: 'cash',
    KUSHKI: 'kushki',
    MANGOPAY: 'mangopay',
    RAPPIPAY: 'rappipay',
    REDSYS: 'redsys',
    TPV: 'tpv'
  });

})();
